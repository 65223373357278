import { api, currentCreche } from '../api'

const AttendanceDocumentsRepository = {
    index (model, uuid, crecheUuid = null) {
        return api().$get(`/${crecheUuid || currentCreche().uuid}/${model}/${uuid}/attendance-documents`)
    },

    create (model, uuid, payload, crecheUuid = null) {
        return api().$post(`/${crecheUuid || currentCreche().uuid}/${model}/${uuid}/attendance-documents`, payload)
    },

    show (model, modelUuid, uuid, crecheUuid = null) {
        return api().$get(`/${crecheUuid || currentCreche().uuid}/${model}/${modelUuid}/attendance-documents/${uuid}`)
    },

    update (model, modelUuid, uuid, payload, crecheUuid = null) {
        return api().$put(`/${crecheUuid || currentCreche().uuid}/${model}/${modelUuid}/attendance-documents/${uuid}`, payload)
    },

    upload (model, modelUuid, uuid, payload, crecheUuid = null) {
        return api().$post(`/${crecheUuid || currentCreche().uuid}/${model}/${modelUuid}/attendance-documents/${uuid}/upload`, payload)
    },

    delete (model, modelUuid, uuid, crecheUuid = null) {
        return api().$delete(`/${crecheUuid || currentCreche().uuid}/${model}/${modelUuid}/attendance-documents/${uuid}`)
    }
}

export default AttendanceDocumentsRepository
